
/* Smartphones (landscape) ----------- */
@media only screen and (min-width : 420px) {
 .register-wrapper {
	  position: absolute;
	  width: 100%;
	  z-index: 10;
	  height: 100%;
	  overflow-y: scroll;
	  padding-bottom: 100px;
	} 
}