.custom-select {
	width: 32rem;
	height: 48px;
}

.card-button {
	width: 100%;
	height: 48px;
	margin-bottom: 16px;
	margin-left: 0px !important;
}

.hide {
   position: absolute !important;
   top: -9999px !important;
   left: -9999px !important;
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width : 420px) {
 .add-wrapper {
	  position: absolute;
	  width: 100%;
	  z-index: 10;
	  height: 100%;
	  overflow-y: scroll;
	  padding-bottom: 100px;
	}

	
}

@media only screen and (max-width : 419px) {
	.custom-select {
		width: 16rem;
		height: 48px;
	}
}

