body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  background-color: #A9A9A9;
      position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    overflow:hidden;
    z-index:-1; /* Remove this line if it's not going to be a background! */
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80vmin;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.navbar {
	height: 6rem;
	background-color: #025 !important;
}

.form-inline .form-control{
   width:600px;
   height: 50px;
}

.white-icon {
    color: white;
}

@media (min-width: 768px) {
	.navbar-expand-md {
		justify-content: center;
	}
}

.btn {
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
}

.search-button {
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
}

.nav-column-2 {
	width: 45rem;
}

.logo-image {
	border-radius: 50%;
	width: 80px;
	height: 80px;
}

.nav-link {
	padding-top: 5px;
	padding-bottom: 0px;
}

.navbutton {
	height: 50px;
}

.flex-column {
	display: flex;
  justify-content: space-between;
}

.navbar-nav {
	flex-direction: row;
	margin-right: 1rem !important;
}

@media only screen and (max-width : 990px) {
	.form-inline .form-control{
  	width: 80%;
	}

	.nav-link {
		padding-top: 10px;
		padding-bottom: 0px;
	}

	.nav-column-2 {
		width: 100%;
	}
}

/* Smartphones (portrait, slightly larger) ----------- */
@media only screen and (max-width : 780px) {
	li {
		padding-top: 0rem;
	}

	.navbar {
		padding: 0px 0px !important;
		height: 6rem;
	}

	.form-inline .form-control{
  	width: 100%;
  	display: inline;
  	padding: 2px 0 0 5px;
  	border: 0;
  	margin-top: 0px;
  	height: 50px;
	}

	.ml-3, .mx-3 {
		margin-left: 0rem!important;
	}

	.my-2 {
		margin-top: 0px !important;
	}

	.btn {
		margin-left: 10px;
		margin-bottom: 6px !important;
		border: 0px;
		padding-bottom: 8px;
	}

	.navbutton {
		background-color: #025;
		color: #025;
		position: absolute !important;
   	top: -9999px !important;
   	left: -9999px !important;
	}

	.nav-column-2 {
		width: 71%;
	}

	.logo-image {
		width: 80px;
		height: 80px;
	}

	.nav-link {
		padding-top: 10px;
		padding-bottom: 0px;
	}

	.logo-image {
  	margin-left: 15px;
  	margin-bottom: 6px;
  }

  .navbar-nav {
		margin-right: .5rem !important;
	}
}

/* Smartphones (portrait, slightly larger) ----------- */
@media only screen and (max-width : 360px) {
	.btn {
		margin-left: 10px;
		border: 0px;
		padding-bottom: 8px;
	}

	.nav-column-2 {
		width: 65%;
	}
}


/* Smartphones (landscape) ----------- */
@media only screen and (min-width : 420px) {
 .register-wrapper {
	  position: absolute;
	  width: 100%;
	  z-index: 10;
	  height: 100%;
	  overflow-y: scroll;
	  padding-bottom: 100px;
	} 
}
.custom-select {
	width: 32rem;
	height: 48px;
}

.card-button {
	width: 100%;
	height: 48px;
	margin-bottom: 16px;
	margin-left: 0px !important;
}

.hide {
   position: absolute !important;
   top: -9999px !important;
   left: -9999px !important;
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width : 420px) {
 .add-wrapper {
	  position: absolute;
	  width: 100%;
	  z-index: 10;
	  height: 100%;
	  overflow-y: scroll;
	  padding-bottom: 100px;
	}

	
}

@media only screen and (max-width : 419px) {
	.custom-select {
		width: 16rem;
		height: 48px;
	}
}


.card-list {
  position: absolute;
  width: 100%;
  z-index: 10;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 100px;
}
.card {
	margin: 0 auto; /* Added */
	float: none; /* Added */
	margin-bottom: 10px; /* Added */
	margin-top: 10px; /* Added */
	width: 60rem;
}

.card-left {
  text-align: left;
}

#ex3 .fa-stack[data-count]:after{
  position:absolute;
  right:-2%;
  top:-2%;
  content: attr(data-count);
  font-size:70%;
  padding:.6em;
  border-radius:50%;
  line-height:2em;
  color: white;
  background:rgba(255,0,0,.85);
  text-align:center;
  min-width: 3em;
  font-weight:bold;
}

.added-by {
  background-color: #ECECDA;
  border-radius: 25px;
  padding: 5px;
}

.img-shadow {
  -webkit-filter: drop-shadow(2px 2px 2px #808080);
  filter: drop-shadow(2px 2px 2px #808080);
}

.img-shadow:hover{
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  /*animation: shake 0.5s;
  animation-iteration-count: infinite;*/
}

.card-body {
  padding: .25rem;
}

.card-link {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.card-link:hover, .card-link:focus {
  text-decoration: none;
  outline: none;
}

.card-text {
  white-space: pre-wrap;
}

.verticalListItem  {
    display: block;
    overflow: hidden;
}

.verticalItemImage {
    float: left;
    margin-right: .5rem;
}
.verticalItemImage img {
    display: block;       
}

.no-margin {
  margin-bottom: 0px;
}

.tweet-card {
  padding: .1rem;
  width: 95%;
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width : 420px) and (max-width : 1000px) {
  .card {
    width: 95%;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width : 419px) {
  .card {
    width: 95%;
  }
}

@-webkit-keyframes shake {
  0% { -webkit-transform: translate(1px, 1px) rotate(0deg); transform: translate(1px, 1px) rotate(0deg); }
  10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); transform: translate(-1px, -2px) rotate(-1deg); }
  20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); transform: translate(-3px, 0px) rotate(1deg); }
  30% { -webkit-transform: translate(3px, 2px) rotate(0deg); transform: translate(3px, 2px) rotate(0deg); }
  40% { -webkit-transform: translate(1px, -1px) rotate(1deg); transform: translate(1px, -1px) rotate(1deg); }
  50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); transform: translate(-1px, 2px) rotate(-1deg); }
  60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); transform: translate(-3px, 1px) rotate(0deg); }
  70% { -webkit-transform: translate(3px, 1px) rotate(-1deg); transform: translate(3px, 1px) rotate(-1deg); }
  80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); transform: translate(-1px, -1px) rotate(1deg); }
  90% { -webkit-transform: translate(1px, 2px) rotate(0deg); transform: translate(1px, 2px) rotate(0deg); }
  100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes shake {
  0% { -webkit-transform: translate(1px, 1px) rotate(0deg); transform: translate(1px, 1px) rotate(0deg); }
  10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); transform: translate(-1px, -2px) rotate(-1deg); }
  20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); transform: translate(-3px, 0px) rotate(1deg); }
  30% { -webkit-transform: translate(3px, 2px) rotate(0deg); transform: translate(3px, 2px) rotate(0deg); }
  40% { -webkit-transform: translate(1px, -1px) rotate(1deg); transform: translate(1px, -1px) rotate(1deg); }
  50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); transform: translate(-1px, 2px) rotate(-1deg); }
  60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); transform: translate(-3px, 1px) rotate(0deg); }
  70% { -webkit-transform: translate(3px, 1px) rotate(-1deg); transform: translate(3px, 1px) rotate(-1deg); }
  80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); transform: translate(-1px, -1px) rotate(1deg); }
  90% { -webkit-transform: translate(1px, 2px) rotate(0deg); transform: translate(1px, 2px) rotate(0deg); }
  100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); transform: translate(1px, -2px) rotate(-1deg); }
}

.container {
  position: relative;
  text-align: center;
  color: white;
}

/* relevant styles */
.img__wrap {
  position: relative;
  height: 100px;
  width: 100px;
}

.img__description {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
  color: #fff;
  visibility: hidden;
  opacity: 0;

  /* transition effect. not necessary */
  transition: opacity .2s, visibility .2s;
}

.img__wrap:hover .img__description {
  visibility: visible;
  opacity: 1;
}

/* Smartphones (portrait, slightly larger) ----------- */
@media only screen and (max-width : 419px) {
  .circle-text {
    fill: white !important;
  }
}
.jumbotron {
	background-color:#bfbfbf !important;
}

@media (min-width: 576px){
	.jumbotron {
	    padding: 2rem 2rem;
	}
}

@media (min-width: 375px){
	.jumbotron {
	    padding: .25rem 1rem;
	}
}
