.navbar {
	height: 6rem;
	background-color: #025 !important;
}

.form-inline .form-control{
   width:600px;
   height: 50px;
}

.white-icon {
    color: white;
}

@media (min-width: 768px) {
	.navbar-expand-md {
		justify-content: center;
	}
}

.btn {
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
}

.search-button {
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
}

.nav-column-2 {
	width: 45rem;
}

.logo-image {
	border-radius: 50%;
	width: 80px;
	height: 80px;
}

.nav-link {
	padding-top: 5px;
	padding-bottom: 0px;
}

.navbutton {
	height: 50px;
}

.flex-column {
	display: flex;
  justify-content: space-between;
}

.navbar-nav {
	flex-direction: row;
	margin-right: 1rem !important;
}

@media only screen and (max-width : 990px) {
	.form-inline .form-control{
  	width: 80%;
	}

	.nav-link {
		padding-top: 10px;
		padding-bottom: 0px;
	}

	.nav-column-2 {
		width: 100%;
	}
}

/* Smartphones (portrait, slightly larger) ----------- */
@media only screen and (max-width : 780px) {
	li {
		padding-top: 0rem;
	}

	.navbar {
		padding: 0px 0px !important;
		height: 6rem;
	}

	.form-inline .form-control{
  	width: 100%;
  	display: inline;
  	padding: 2px 0 0 5px;
  	border: 0;
  	margin-top: 0px;
  	height: 50px;
	}

	.ml-3, .mx-3 {
		margin-left: 0rem!important;
	}

	.my-2 {
		margin-top: 0px !important;
	}

	.btn {
		margin-left: 10px;
		margin-bottom: 6px !important;
		border: 0px;
		padding-bottom: 8px;
	}

	.navbutton {
		background-color: #025;
		color: #025;
		position: absolute !important;
   	top: -9999px !important;
   	left: -9999px !important;
	}

	.nav-column-2 {
		width: 71%;
	}

	.logo-image {
		width: 80px;
		height: 80px;
	}

	.nav-link {
		padding-top: 10px;
		padding-bottom: 0px;
	}

	.logo-image {
  	margin-left: 15px;
  	margin-bottom: 6px;
  }

  .navbar-nav {
		margin-right: .5rem !important;
	}
}

/* Smartphones (portrait, slightly larger) ----------- */
@media only screen and (max-width : 360px) {
	.btn {
		margin-left: 10px;
		border: 0px;
		padding-bottom: 8px;
	}

	.nav-column-2 {
		width: 65%;
	}
}
