.jumbotron {
	background-color:#bfbfbf !important;
}

@media (min-width: 576px){
	.jumbotron {
	    padding: 2rem 2rem;
	}
}

@media (min-width: 375px){
	.jumbotron {
	    padding: .25rem 1rem;
	}
}