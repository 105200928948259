.card {
	margin: 0 auto; /* Added */
	float: none; /* Added */
	margin-bottom: 10px; /* Added */
	margin-top: 10px; /* Added */
	width: 60rem;
}

.card-left {
  text-align: left;
}

#ex3 .fa-stack[data-count]:after{
  position:absolute;
  right:-2%;
  top:-2%;
  content: attr(data-count);
  font-size:70%;
  padding:.6em;
  border-radius:50%;
  line-height:2em;
  color: white;
  background:rgba(255,0,0,.85);
  text-align:center;
  min-width: 3em;
  font-weight:bold;
}

.added-by {
  background-color: #ECECDA;
  border-radius: 25px;
  padding: 5px;
}

.img-shadow {
  -webkit-filter: drop-shadow(2px 2px 2px #808080);
  filter: drop-shadow(2px 2px 2px #808080);
}

.img-shadow:hover{
  transform: scale(1.2);
  /*animation: shake 0.5s;
  animation-iteration-count: infinite;*/
}

.card-body {
  padding: .25rem;
}

.card-link {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.card-link:hover, .card-link:focus {
  text-decoration: none;
  outline: none;
}

.card-text {
  white-space: pre-wrap;
}

.verticalListItem  {
    display: block;
    overflow: hidden;
}

.verticalItemImage {
    float: left;
    margin-right: .5rem;
}
.verticalItemImage img {
    display: block;       
}

.no-margin {
  margin-bottom: 0px;
}

.tweet-card {
  padding: .1rem;
  width: 95%;
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width : 420px) and (max-width : 1000px) {
  .card {
    width: 95%;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width : 419px) {
  .card {
    width: 95%;
  }
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.container {
  position: relative;
  text-align: center;
  color: white;
}

/* relevant styles */
.img__wrap {
  position: relative;
  height: 100px;
  width: 100px;
}

.img__description {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
  color: #fff;
  visibility: hidden;
  opacity: 0;

  /* transition effect. not necessary */
  transition: opacity .2s, visibility .2s;
}

.img__wrap:hover .img__description {
  visibility: visible;
  opacity: 1;
}

/* Smartphones (portrait, slightly larger) ----------- */
@media only screen and (max-width : 419px) {
  .circle-text {
    fill: white !important;
  }
}